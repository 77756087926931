<template>
  <div id="app">
    <div :class="isMobile?'mhome home':'home'">
      <!-- 头部 -->
      <div class="header">
        <div class="title"><img style="margin-right:12px;width:40px;height:40px;vertical-align: middle;"
            src="./rainchapter.png" />RCHub</div>
        <div class="typeContent">
          <div class="typeItem" v-for="type in typeList" :key="type.id" :class="type.id === currentType ? 'selected' : ''"
            @click="selectType(type)">{{ type.name }}</div>
        </div>
        <div class="search">
          <el-select v-model="modeId" @change="searchChange" clearable filterable placeholder="搜索">
            <el-option v-for="item in searchItemList" :label="item.name" :value="item.id" :key="item.id" />
          </el-select>
        </div>
      </div>
      <!-- 菜单和html -->
      <div class="body">
        <!-- 菜单 -->
        <div class="menu">
          <el-menu style="width:100%" :default-active="defaultValue" :unique-opened="!isMobile" @select="selectMenu">
            <component class="menu-item" v-for="item in menuList" :key="item.id"
              :is="item.type===2?'el-submenu':'el-menu-item'" :index="item.id">
              <template slot="title">
                <div style="text-align:left">{{ item.name }}</div>
              </template>
              <template v-if="item.type === 2">
                <el-menu-item class="menu-item" v-for="(v) in item.articles" :key="v.id" :index="v.id">
                  <div :title="v.name" slot="title" class="menuText">
                    {{ v.name }}
                  </div>
                </el-menu-item>
              </template>
            </component>
          </el-menu>
        </div>
        <router-view :key="defaultValue"/>
        <div class="placement">
          <img src="./erweima.jpg" style="width:180px;height:180px" alt="">
        </div>
      </div>
      <div class="footer">
        <div>本站内容若有疑问，请联系我们！本站内容若有投稿，请联系我们！微信号：vae_18718417101联系邮箱：yhc@rainchapter.com</div>
        <div>备案号：粤ICP备17048987号-1</div>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api/index'
export default {
  name: 'App',
  data() {
    return {
      modeId: '',
      defaultValue: '', // 默认选中值
      typeList: [],
      searchItemList: [],
      menuList: [],
      currentType: '',
      currentArticleId: '',
      htmlValue: '',
      isMobile: /Mobile/i.test(navigator.userAgent)
    }
  },
  methods: {
    selectMenu(e) {
      this.defaultValue = e
      // 获取html
      this.$router.push({
        path: `/index/${e}`
      })
      if (this.isMobile) {
        this.$nextTick(()=>{
          document.getElementById('markdown_body').scrollIntoView()
        })
      }
    },
    // 搜索跳转
    searchChange(id) {
      if (id) {
        const item = this.searchItemList.find(el => el.id === id)
        const type = this.typeList.find(t => t.id === item.typeId)
        const articleId = item.type === 2 ? item.redirectId : item.id
        this.selectType(type, articleId)
      }
    },
    // 选中类型
    selectType(type, id) {
      this.defaultValue = ''
      this.currentType = type.id
      // 构造菜单数组，并选中第一个文章
      this.menuList = type.books.map(book => {
        const obj = { ...book }
        if (book.type === 1) {
          obj.name = book.articles[0].name
          obj.id = book.articles[0].id
          if (!this.defaultValue) {
            if (!id) {
              this.selectMenu(obj.id)
            } else {
              this.selectMenu(id)
            }
          }
        }
        if (book.type === 2 && book.articles.length && !this.defaultValue) {
          // this.defaultValue = book.articles[0].id
          if (!id) {
            this.selectMenu(book.articles[0].id)
          } else {
            this.selectMenu(id)
          }
        }
        return obj
      })
    }
  },
  mounted() {
    const articleId = location.href.includes('/index/') ? location.href.split('/index/')[1] : ''
    console.log(articleId);
    
    // 获取所有的菜单，并洗数据
    api.getMenu().then(res => {
      this.typeList = res.data || []
      if (this.typeList.length && !articleId) {
        this.selectType(this.typeList[0])
      }
      // 构造搜索数组
      this.typeList.forEach(item => {
        item.books.forEach(book => {
          let obj = {
            name: book.name,
            id: book.id,
            typeId: item.id,
            type: book.type
          }
          if (book.type === 2 && book.articles && book.articles.length) {
            obj.redirectId = book.articles[0].id
            this.searchItemList.push(obj)
            // 文章的
            book.articles.forEach(art => {
              this.searchItemList.push({
                ...art,
                bookId: book.id,
                typeId: item.id
              })
            })
          }
          if (book.type === 1 && book.articles && book.articles.length) {

            obj.name = book.articles[0].name
            obj.id = book.articles[0].id
            this.searchItemList.push(obj)
          }

        })
      })
      if (articleId) {
        this.searchChange(articleId)
      }
    })
  }
}
</script>

<style lang="scss">
.home {
  background-color: #fff;
  width: 100vw;
  height: 100vh;
  padding: 0 20px;
  box-sizing: border-box;
  overflow-y: auto;
  .header{
    height: 60px;
    width: 100%;
    border-bottom: solid 1px #ddd;
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    padding: 0 20px;
    box-sizing: border-box;
    .title{
      color: #1989fa;
      font-weight: bold;
      font-size: 28px;
      line-height: 60px;
      width:250px;
      text-align: center;
    }
    .typeContent{
      width:calc(100% - 500px);
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #666;
      font-weight: bold;
      .typeItem{
        cursor: pointer;
      }
      .selected{
        color: #1989fa!important;
      }
    }
    .search{
      line-height: 60px;
      width: 250px;
      text-align: right;
    }
  }
  .body{
    height: calc(100% - 110px);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    .menu{
      width: 250px;
      height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      border-right: solid 1px #ddd;
      box-sizing: border-box;
      .menuText{
        text-align: left;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .htmlContent{
      flex: 1;
      height: 100%;
      overflow-y: auto;
      text-align: left;
      padding: 20px;
      box-sizing: border-box;
    }
    .placement{
      height: 100%;
      width: 200px;
      display: flex;
      align-items: flex-start;
      padding: 50px 0;
      flex-wrap: wrap;
      justify-content: center;
      box-sizing: border-box;
    }
  }
  .footer{
    height: 50px;
    line-height: 25px;
    text-align: center;
    overflow: hidden;
    color: #999;
    font-size: 12px;
  }
}
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
  padding: 0;
}

.el-button--text {
  color: #007185 !important
}

.el-dialog__header {
  padding: 10px 20px !important;
  text-align: left;
  background: #f5f5f5;

  .el-dialog__title {
    font-size: 16px !important;
  }

  .el-dialog__headerbtn {
    top: 15px !important;
  }
}
.mhome{
  padding: 0 8px!important;
  .header{
    display: block!important;
    height: auto!important;
    border: none!important;
    .typeContent{
      width: 100%!important;
    }
  }
  .body{
    height: auto!important;
    display: block!important;
    .menu{
      width: 100%!important;
      height: auto!important;
      border: none!important;
    }
    .htmlContent{
      height: auto!important;
      width: 100%!important;
      padding: 20px 0!important;
    }
    .placement{
      display: inline-block!important;
      height: auto!important;
    }
  }
}
</style>
