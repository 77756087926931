import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import router from '@/router'
import App from './App.vue'
import '@/styles/index.scss' // global css
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI, { size: 'small', zIndex: 3000 })
// 图片查看插件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer)
Viewer.setDefaults({
  inline: false, // 启用inline模式
  button: false, // 显示右上角关闭按钮
  navbar: false, // 显示缩略图导航
  title: false, // 显示当前图片的标题
  toolbar: false, // 显示工具栏
  tooltip: true, // 显示缩略百分比
  movable: true, // 图片是否可移动
  zoomable: true, // 图片是否可缩放
  rotatable: true, // 图片是否可旋转
  scalable: true, // 图片是否可反转
  transition: true, // 使用css3过度
  fullscreen: false, // 播放时是否全屏
  keyboard: true, //
})
Vue.config.productionTip = false

import store from '@/store/index.js'
import * as echarts from 'echarts'
Vue.prototype.$echarts = echarts
const vue = new Vue({
  el: '#app',
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
export default vue
