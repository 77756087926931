import request from "@/utiles/request";
const BASE_URL = "/api";
const api = {
  // 获取菜单
  getMenu() {
    return request({
      method: "get",
      url: `${BASE_URL}/books/getHubMap`,
    });
  },
  // 获取文章
  getArticleById(params) {
    return request({
      method: "get",
      url: `${BASE_URL}/books/getArticleById`,
      params,
    });
  },
};
export default api;
