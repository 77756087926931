/**
 * @description [ axios 请求封装]
 */
import axios from "axios";
import { Message } from "element-ui";
import { saveAs } from "file-saver";
import md5 from "js-md5";
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 60000,
  withCredentials: true, // send cookies when cross-domain requests
  headers: {
    // clear cors
    "Cache-Control": "no-cache",
    Pragma: "no-cache",
  },
});
// Request interceptors
let isCustom = false;
let unNeedReload = false;
service.interceptors.request.use(
  (config) => {
    const timestamp = new Date().getTime() + "";

    const noncer = Math.ceil(Math.random() * 1000) + "";
    config.headers["noncer"] = noncer;
    config.headers["timestamp"] = timestamp;
    // dev
    // config.headers["sign"] = md5(noncer + timestamp + "/rchub" + config.url);
    // 线上
    config.headers["sign"] = md5(noncer + timestamp + config.url);
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptors
service.interceptors.response.use(
  (response) => {
    // 判断response是Blob对象则直接下载
    if (response.config.responseType === "blob") {
      try {
        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        saveAs(
          blob,
          response.headers["content-disposition"].split("filename=")[1]
        );
        return Promise.resolve("ok");
      } catch (e) {
        return Promise.resolve(e);
      }
    }
    const res = response.data;
    // let body = document.getElementsByTagName('body')
    if (isCustom) {
      return response.data;
    }
    // 后台code处理  || res.status !== 200
    if ((res.code === 401 || res.code === 1013) && !unNeedReload) {
      localStorage.removeItem("token");
      location.reload();
      return;
    }
    if (res.code === 403) {
      window.location.href = "./login";
    }

    if (res.code !== 200 && res.code !== null) {
      Message({
        showClose: true,
        message: res.message || res.msg,
        type: "error",
      });

      return Promise.reject(response.data);
    } else {
      return response.data;
    }
  },
  (error) => {
    if (error && error.response) {
      // switch (error.response.status) {
      //   case 400: error.message = '请求错误(400)'; break
      //   case 401: error.message = '未授权,请登录(401)'; break
      //   case 403: error.message = '拒绝访问(403)'; break
      //   case 404: error.message = `请求地址出错: ${error.response.config.url}`; break
      //   case 405: error.message = '请求方法未允许(405)'; break
      //   case 408: error.message = '请求超时(408)'; break
      //   case 500: error.message = '服务器内部错误(500)'; break
      //   case 501: error.message = '服务未实现(501)'; break
      //   case 502: error.message = '网络错误(502)'; break
      //   case 503: error.message = '服务不可用(503)'; break
      //   case 504: error.message = '网络超时(504)'; break
      //   case 505: error.message = 'HTTP版本不受支持(505)'; break
      //   default: error.message = `连接错误: ${error.message}`
      // }
      console.log(error.response);
      error.message = error.response.data.result;
    } else {
      console.log(error);
      // error.message = '连接到服务器失败，请联系管理员'
      error.message = "Network Error";
    }
    Message({
      showClose: true,
      message: error.info || error.message,
      type: "error",
    });
    return Promise.reject(error);
  }
);

export default service;
