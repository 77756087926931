import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)
const store = new Vuex.Store({
  state:{
    timeZone: localStorage.getItem('timeZone')||'+8'
  },
  mutations:{
    setTimeZone(state,val){
      localStorage.setItem('timeZone',val)
      state.timeZone = val
    }
  }
})

export default store